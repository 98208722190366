// pages/_error.tsx
"use client";
import * as Sentry from "@sentry/nextjs";

import TypographyMontserrat from "../components/Atoms/TypographyMontserrat";
import NotFound404 from "../components/Organisms/NotFound404";

function Error(err) {
    return <NotFound404 />;
}

Error.getInitialProps = ({ res, err }) => {
    if (err) {
        Sentry.captureException(err);
    }

    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return { statusCode };
};

export default Error;
